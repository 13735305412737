export default {
  text: '#252323',
  primary: '#A99985',
  secondary: '#DAD2BC',
  tertiary: '#70798C',
  background: '#F5F1ED',
  backgroundSecondary: '#DAD2BC',
  light: '#FFF',
  dark: '#252323',
};
