export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: ['', '', '', '3rem 2rem'],
      '.hamburger': {
        right: '1rem',
        '> div': {
          backgroundColor: 'white',
        },
      },
      '.smallNavMenu': {
        '> div': {
          borderColor: 'white',
          a: {
            color: 'white',
          },
        },
      },
    },

    '.containerScrolled': {
      backgroundColor: 'white',
      color: 'black',
      padding: '0rem',
      borderBottom: 'solid 1px black',
      '.smallNavMenu': {
        '> div': {
          borderColor: 'black',
          a: {
            color: 'black',
          },
        },
      },
      '.hamburger': {
        '> div': {
          backgroundColor: 'black',
        },
      },
    },

    '.logo': {
      filter: 'brightness(0) invert(1)',
    },
    '.logoScrolled': {
      // filter: 'brightness(0)',
      img: {
        maxHeight: '60px',
      },
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '3rem 0rem',
      img: {
        filter: 'brightness(0) invert(1)',
      },
    },

    '.navMenuOpen': {
      color: 'white',
    },
  },

  footer: {
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    '.multiButtonContainer': {
      a: {
        variant: 'buttons.secondary',
      },
    },
    '.gonationLogo': {
      width: 'fit-content',
    },
    '.socialContainer': {
      svg: {
        path: {
          fill: 'primary',
        },
      },
    },
  },

  ctaWidget: {
    a: {
      fontSize: ['1.2rem', '', '1.25rem'],
      padding: '0.75rem',
      minWidth: ['40%', '', 'unset'],
    },
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'white',
    order: '1',
  },
  subtitle: {
    color: 'secondary',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    textShadow: '2px 2px 10px black',
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'center',
    lineHeight: '1.75',
    order: '3',
    p: {
      lineHeight: '1.75',
      textAlign: 'center',
      fontSize: 'inherit',
    },
  },

  // sideBySide1: {
  //   padding: '1rem',
  //   '.lazyload-wrapper': {
  //     maxHeight: '75vh',
  //   },
  //   '.content': {
  //     // variant: 'customVariants.borderCorners',
  //     position: 'relative',
  //     alignItems: 'flex-start',
  //     margin: ['1rem 0rem', '', '0rem 1rem'],
  //   },
  //   '.title': {
  //     variant: 'customVariants.title',
  //   },
  //   '.subtitle': { variant: 'customVariants.subtitle' },
  //   '.text': {
  //     variant: 'customVariants.text',
  //   },
  //   a: {
  //     order: '4',
  //   },
  //   '.ctaButton': {
  //     variant: 'buttons.secondary',
  //   },
  // },

  // sideBySide2: {
  //   variant: 'customVariants.sideBySide1',
  //   '.imageContainer': {
  //     height: ['', '', '75vh'],
  //   },
  //   '.content': {
  //     // variant: 'customVariants.borderCorners',
  //     order: '2',
  //     position: 'relative',
  //     alignItems: 'flex-start',
  //     margin: ['1rem 0rem', '', '0rem 1rem'],
  //   },
  //   a: {
  //     order: '4',
  //   },
  // },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    marginTop: '1rem',
    '.section': {
      margin: 'auto auto',
      padding: '0.5rem',
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center',
      },
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  homepageHeroShout: {},

  homepageAbout: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
  },
  homepageCatering: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
  },

  homepageMenu: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
  },

  homepageGallery: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
  },
  homepageEvents: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
  },

  homepageContact: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'secondary',
      fontFamily: 'body',
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['primary', '', '', 'primary'],
      color: 'white',
    },
    '.date': {
      backgroundColor: 'dark',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
    },

    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background',
    },
  },

  instagramWidget: {
    backgroundColor: '#f2f5f0',
    '.logoAndTextContainer': {
      display: 'none',
    },
    '.iFrameContainer': {
      width: '100%',
    },
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1',
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1',
  },
  venueBar: {
    variant: 'customVariants.sideBySide1',
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
    },
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem',
      height: 'unset',
      width: 'fit-content',
      borderRadius: '0px',
      padding: '1rem 1.5rem',
      color: 'black',
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: 'primary',
        color: 'white',
      },
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2',
      },
    },
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset',
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        // filter: 'brightness(0) invert(1)',
      },
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%',
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary',
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none',
    },
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'tertiary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white',
      },
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto',
    },
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem',
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center',
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem',
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem'],
      },
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid',
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
    },
  },

  socialSection: {
    order: '3',
    backgroundColor: 'backgroundSecondary',
    svg: {
      path: {
        fill: 'primary',
      },
    },
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black',
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem',
      },
    },
  },

  pressPage: {
    backgroundColor: 'background',
  },
};
